.card{
	position: relative;
	&--request{
		display: flex;
		padding: 1rem;
		flex-direction: column;
		.card__title{
			color: #1E2F46;
			font-size: 1.125rem;
			padding: 0 0 .25rem;
			font-weight: 600;
			.bi{
				margin-right: .5rem;
			}
		}
		.card__subtitle{
			color: #8B95A2;
			font-weight: 400;
			display: flex;
			align-items: center;
			.bi,.ico{
				margin-right: .5rem;
			}
		}
		.card__header{
			display: flex;
		}
		.card__content{
			border-top: solid 1px #E7EAF3;
			padding-top: 1rem;
		}
		.card__column{
			display: flex;
			flex-direction: column;
	 	}
	}
	&.is-highlight{
		border: solid 1px $color-third;
		.badge--position{
			background-color: $color-third;
			color: $white;
		}
	}
	.badge--position{
		position: absolute;
		right: 1rem;
		top: 1rem;
	}
	&__jar_status{
		display: grid;
		gap: .5rem;
		grid-template-columns: repeat(auto-fit, minmax( unquote(#{'min(100%, 9rem)'}), 1fr));
	}
	&__header{
		&.is-grid{
			display: grid;
			gap: 1.5rem;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}
	}
	&--batch{
		.card__title{
			display: flex;
			align-items:center;
			gap:.75rem;
		}
		.card__figure{
			background-color:#EEE1CE;
			margin:0;
			color:#A8650A;
			line-height:100%;
			border-radius: .3125rem;
			padding:.5rem;
			.bi,.ico{
				line-height: 100%;
			}
		}
		.card__info{
			display:flex;
			flex-direction: column;
			strong{
				color: #A8650A;
				font-size:1.5625rem;
				line-height:100%;
				font-weight:bold;
			}
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__progress{
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__amount{
			font-size:1.5625rem;
			line-height: 100%;
			color:#1E2F46;
			display:block;
		}
	}
	&--analysis{
		.card__title{
			display: flex;
			align-items:center;
			gap:.75rem;
		}
		.card__figure{
			background-color:#E5D7E4;
			margin:0;
			color:#813E79;
			line-height:100%;
			border-radius: .3125rem;
			padding:.5rem;
			.bi,.ico{
				line-height: 100%;
			}
		}
		.card__info{
			display:flex;
			flex-direction: column;
			strong{
				color: #813E79;
				font-size:1.25rem;
				line-height:100%;
				font-weight:bold;
			}
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__progress{
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__amount{
			font-size:1.5625rem;
			line-height: 100%;
			color:#1E2F46;
			display:block;
		}
	}
	&--sample{
		.card__title{
			display: flex;
			align-items:center;
			gap:.75rem;
		}
		.card__figure{
			background-color:#CCD4DE;
			margin:0;
			color:#1E2F46;
			line-height:100%;
			border-radius: .3125rem;
			padding:.5rem;
			min-width: 2.3125rem;
			display: flex;
			justify-content: center;
			align-items: center;
			.bi,.ico{
				line-height: 100%;
			}
		}
		.card__info{
			display:flex;
			flex-direction: column;
			strong{
				color: #1E2F46;
				font-size:1.25rem;
				line-height:100%;
				font-weight:bold;
			}
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__progress{
			small{
				color: #7897AB;
				font-size:.75rem;
				line-height: 100%;
			}
		}
		.card__amount{
			font-size:1.5625rem;
			line-height: 100%;
			color:#1E2F46;
			display:block;
		}
	}
}

.grid-blocks{
  gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 20rem)'}, 1fr));
}
