.form{
	&--sequence{
		display: flex;
		justify-content: space-between;
		input{
			width: 2.25rem;
			height: 3rem;
			text-align: center;
			font-size: 1.5rem;
			border-radius: .3125rem;
			border: solid 1px #8B95A2;
			@media screen and (max-width:$md) {
				width: 1.5rem;
				height: 2.2rem;
			}
		}
	}
	&__row{
		display: flex;
		flex-direction: row;
	}
	&__header{
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
	}
}
.input{
	display: block;
	width: 100%;
	padding: .25rem .7rem;
	font-size: .875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .2rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
