.landing{
	background-color: var(--ds-color-third);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: stretch;
	align-items: stretch;
	min-height: 100vh;
	color: $white;

	&__header{
		flex: 0 1 auto;
    align-self: flex-start;
		width: 50%;
		max-width: 34rem;
		min-width: 20rem;
		img{
			width: 100%;
		}
	}
	&__content{
		align-self: center;
		display: flex;
		justify-content: center;
		width: 80%;
		max-width: 50ch;
		text-align: center;
		flex-direction: column;
		h2{
			font-size: 2.4rem;
			font-weight: 100;
			margin-bottom: 2rem;
			@media screen and (max-width:$md) {
				font-size: 2rem;
			}
		}
		a{
			align-self: center;
		}
	}
	&__footer{
    flex: 0 1 auto;
    align-self: flex-end;
		width: 25%;
		max-width: 18rem;
		min-width: 10rem;
		flex-grow: .5;
		display: flex;
		align-items: flex-end;
		img{
			width: 100%;
		}
	}
}
