@font-face {
	font-family: "project-icons";
	src: url("assets/fonts/project-icons.eot?426fb615c7dc515f792db1dd6c4eeb4f?#iefix") format("embedded-opentype"),
url("assets/fonts/project-icons.woff2?426fb615c7dc515f792db1dd6c4eeb4f") format("woff2"),
url("assets/fonts/project-icons.woff?426fb615c7dc515f792db1dd6c4eeb4f") format("woff"),
url("assets/fonts/project-icons.ttf?426fb615c7dc515f792db1dd6c4eeb4f") format("truetype"),
url("assets/fonts/project-icons.svg?426fb615c7dc515f792db1dd6c4eeb4f#project-icons") format("svg");
}

.ico {
	line-height: 1;
}

.ico:before {
	font-family: project-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ico-building:before {
	content: "\f101";
}
.ico-burger:before {
	content: "\f102";
}
.ico-close:before {
	content: "\f103";
}
.ico-sample-2:before {
	content: "\f104";
}
.ico-sample:before {
	content: "\f105";
}
.ico-search:before {
	content: "\f106";
}
.ico-square-dash:before {
	content: "\f107";
}
.ico-square-plus:before {
	content: "\f108";
}
.ico-vial:before {
	content: "\f109";
}
