@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size:$base-size;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	margin:0;
	padding:0;
}

body {
	font-family:$font-base;
	font-size:$base-size;
	color:$color-text;
	background-color:$color-bg;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin:0;
	padding:0;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
}

p {
	margin-top: 0;
}

ul, ol, dl {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

a,
button,
input,
select {
	transition-property: transform, background, background-color, border-color, color;
	transition-duration: $transition-duration;
}

textarea {
	resize: vertical;
}

a {
	color:$color-text;
	text-decoration: none;
	&:hover{
		color:$color-first;
		text-decoration: underline;
	}
	&:focus {
		outline:none;
	}
}

img {
	vertical-align: middle;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

[role="button"] {
	cursor: pointer;
}

[hidden] {
	display: none !important;
}
#slick-areas{
	margin-bottom:0;
}
main{
	display: flex;
	width: 100%;
	min-width: 0;
	min-height: 100vh;
	transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
	overflow: hidden;
	flex-direction: column;
}
