.report{
	background-color: #ffffff;
	min-width: 60rem;
	max-width: 80rem;
	margin-inline: auto;
	padding: 2.625rem;
	.card{
		flex-basis: 35%;
		padding: 1.25rem;
		background-color: #fff;
		border-radius: .375rem;
		border: 1px solid #D3D3D3;
		@media print {
			box-shadow: none;
			padding: 1rem;
			border: solid 1px #333;
		}
		.description{
			margin-bottom: 0;
			.description__definition{
				margin-bottom: .5rem;
				letter-spacing: 1.1px;
				font-size: .6875rem;
				text-transform: uppercase;
			}
			.description__term{
				color: #666;
				font-size: .8125rem;
				line-height: 1.125rem;
			}
		}
		&:nth-child(2){
			flex-basis: 25%
		}
	}
	.table{
		border-collapse: 0;
		border-spacing: 0;
		border-collapse: collapse;
		width: 100%;
		tr{
			border-bottom: solid 1px #EFEFEF;
			td{
				// border-bottom: solid 1px #EFEFEF;
				font-size: .8125rem;
				padding: .5rem;
				line-height: 100%;
				color: #707070;
				&:first-child{
					color: #000;
				}
				&.col-highlight{
					border-left:4px solid #0045a2;
					border-right:4px solid #0045a2;
				}
			}
			&:first-child{
				td.col-highlight{
					border-top:4px solid #0045a2;
				}
			}
			&:last-child{
				td.col-highlight{
					border-bottom:4px solid #0045a2;
				}
			}
		}
		&--detail{
			thead{
				tr{
					border-bottom: none;
					th{
						padding: .5rem;
						white-space: nowrap;
						font-size: 0.8125rem;
						font-weight: normal;
						&.col-highlight{
							border: 4px solid #0045a2;
							background-color: #0045a2;
							color: #fff;
						}
					}
				}
			}
			tbody{
				border: solid 1px #EFEFEF;
				tr{
					&.border-bottom{
						border-width: 2px;
						border-color: #BCBCBC;
					}
					td.txt--vertical{
						border: solid 1px #EFEFEF;
						transform: rotate(-180deg);
					}
				}
			}
		}
	}
	&__top{
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
		.black{
			display: none;
			@media print {
				display: block;
			}
		}
		.color{
			display: block;
			@media print {
				display: none;
			}
		}

	}
	&__brand{
		display: block;
		img{
			width: 400px;
			height: auto;
			@media print {
				width: 280px;
			}
		}
	}
	&__title{
		h1{
			font-size: 2rem;
			text-transform: uppercase;
			@media print {
				font-size: 1.25rem;
			}
		}
		h3{
			font-size: 1.8125rem;
			color: #707070;
			font-weight: 300;
			@media print {
				font-size: 1rem;
			}
		}
	}
	&__header{
		display: flex;
		gap: 1.5rem;
		width: 100%;
		justify-content: space-between;
		// flex-wrap: wrap;
	}
	&__result{
		display: flex;
		gap: 1.5rem;
	}
	&__state{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: .675rem;
		flex-basis: 20%;
		max-width:12.5rem;
		.bi{
			font-size: 2.5rem;
			align-self: flex-start;
		}
		&--success{
			.bi{
				color: $color-success;
			}
		}
		&--warning{
			.bi{
				color: $color-warning;
			}
		}
		&--danger{
			.bi{
				color: $color-danger;
			}
		}
		@media print {
			color: #000!important;
		}
	}
	&__evaluation{
		flex-grow: 999;
		flex-basis: 80%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 2rem;
		@media print {
			flex-direction: column;
		}
	}
	&__history{
		strong{
			margin-bottom: .5rem;
			letter-spacing: 1.1px;
			font-size: .6875rem;
			text-transform: uppercase;
			display: block;
		}
	}
	&__detail{
		display: flex;
		gap: 1.5rem;
	}
	&__detail{
		flex-basis: 80%;
	}
	&__visual{
		flex-basis: 20%;
	}
	&__actions{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 1rem;
	}
	&__navigation{
		display: flex;
		align-items: center;
	}
}
.firm{
	display: flex;
	align-items: center;
	align-self: flex-end;
	font-size: 0.8125rem;
	color: #707070;
	span, b{
		white-space: nowrap;
	}
	b{
		color: #000;
	}
	img{
		width: 100px;
		height: auto;
	}
}
