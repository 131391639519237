.description{
	display: flex;
	flex-direction: column;
	&__term{
		font-size: 0.9375rem;
	}
	&__definition{
		font-size: 0.9375rem;
		padding: 0;
		margin: 0;
		font-weight: 600;
	}
}
