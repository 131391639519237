.btn{
	padding: 0.6875em 3.125em;
	border-radius: .25em;
	font-size: 1.125rem;
	white-space: nowrap;
	border: none;
	background-color: transparent;
	font-weight: 500;
	text-transform: uppercase;
	&--light {
		background-color: $white;
		color: #4F6D7C;
		&.btn--outline{
			border: solid 1px #4F6D7C;
		}
		&:hover{
			background-color: $color-first;
			color:$white;
			text-decoration: none;
		}
	}
	&--small{
		padding: .625em 0.875em;
		font-size: 0.875rem;

	}
	&--first{
		background-color: #377DFF;
		color: $white;
		&.btn--text{
			color: #377DFF;
			background-color: transparent;
			&:hover{
				background-color: transparent;
				color: $color-first;
			}
		}
		&.btn--outline{
			background-color: transparent;
			border: solid 1px #377DFF;
			color: #377DFF;
		}
		&:hover{
			background-color:$color-first;
			color: $white;
		}
	}
	&--text{
		background-color: transparent;
		border: 0;
		border-radius: 0;
		padding: 0;
		margin: 0;
		&.btn--icon{
			i,em{
				font-size: .8rem;
				height: initial;
				width: initial;
				overflow: inherit;
				&::before{
					margin-top: inherit;
					margin-left: inherit;
				}
			}
		}
	}
	&--second{
		background-color: #6F98AD;
		color:$white;
		&:hover{
			color:$white;
			background-color: #4F6D7C;
		}
	}
	&--success{
		background-color: #1DB117;
		color:$white;
		&:hover{
			color:$white;
			background-color:	#198D14;
		}
	}
	&--danger{
		background-color: $color-danger;
		color: $white;
		&:hover{
			color: $white;
			background-color:	$color-second;
		}
	}
	&--icon{
		display: flex;
		align-items: center;
		i,em{
			height: 1rem;
			width: 1rem;
			overflow: hidden;
			font-size: 1.5rem;
			display: flex;
			line-height: 100%;
			margin-right: .5rem;
			&::before{
				margin-top: -.25rem;
				margin-left: -.25rem;
			}
		}
	}

	&--only-icon{
		padding: 0;
	}
	&:hover{
		cursor: pointer;
		text-decoration: none;
	}
	&[disabled]{
		background-color: #E7EAF3!important;
		color: #97989d!important;
		&:hover{
			cursor: default!important;
		}
		&:focus{
			outline: none!important;
		}
	}
}
