/* Vars */
@import 'scss/project/vars';

/* functions */
@import 'scss/project/mixins';

/* External*/
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/utilities/api";
/* Base */
@import 'scss/project/reset';

/* Components */
@import 'scss/project/components/landing';
@import 'scss/project/components/buttons';
@import 'scss/project/components/badge';
@import 'scss/project/components/sidebar';
@import 'scss/project/components/suggestion';
@import 'scss/project/components/header';
@import 'scss/project/components/content';
@import 'scss/project/components/filters';
@import 'scss/project/components/form';
@import 'scss/project/components/inputs';
@import 'scss/project/components/modal';
@import 'scss/project/components/alert';
@import 'scss/project/components/lists';
@import 'scss/project/components/links';
@import 'scss/project/components/definition';
@import 'scss/project/components/msn';
@import 'scss/project/components/block';
@import 'scss/project/components/cards';
@import 'scss/project/components/grid';
@import 'scss/project/components/tables';
@import 'scss/project/components/text';
@import 'scss/project/components/description';
@import 'scss/project/components/chips';
@import 'scss/project/components/reports';

/* Utilities */
@import 'scss/project/icons';
@import 'scss/project/utilities';
