.ico {
	display: flex;
	&--big{
		font-size: 2.5rem;
	}
	&--medium{
		font-size: 1.75rem;
	}
	&--small{
		font-size: 1rem!important;
	}
}
.wrapper{
	display: flex;
	width: 100%;
	height: 100vh;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.thumb{
	max-width:10rem;
	img{
		width: 100%;
		height: auto;
	}
}

.c-gray{
	color: #8B95A2;
}

.c-success{
	color: $color-success;
}

.c-warning{
	color: $color-warning;
}

.c-danger,
.c-error{
	color: $color-error;
}

.fig{
	position: relative;
	font-size: 1.25rem;
	display: inline-block;
	color: #2D3D53;
	&>em{
		z-index: 0;
	}
	&__badge{
		position: absolute;
		font-size: .75rem;
		z-index: 1;
		bottom: -.25rem;
		right: -.5rem;
		&--warning{
			color: $color-warning;
		}
	}
}

.text{
	&-md{
		font-size: 1.25rem;
	}
	&--success{
		color: $color-success;
	}
	&--warning{
		color: $color-warning;
	}
	&--error{
		color: $color-error;
	}
}

.ico-size{
	&-md{
		font-size: 1.5rem;
	}
}

.no-border{
	border: none!important;
}

.text-danger{
	color: $color-second!important;
	font-size: .75rem;
}
.text-underline{
	text-decoration: underline;
}

@keyframes rotation {
	from {
			-webkit-transform: rotate(0deg);
	}
	to {
			-webkit-transform: rotate(359deg);
	}
}

.analysis{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	&__circle{
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		&::before{
			content: '';
			width: 6rem;
			height: 6rem;
			border: solid 4px #8B95A2;
			border-radius: 3.5rem;
			border-top-color: transparent;
			position: absolute;
			top: 0;
			left: 0;
			animation: rotation 2s infinite linear;
		}
		width: 6rem;
		height: 6rem;
		border-radius: 3.5rem;
		margin-bottom: 1rem;
	}
	.bi{
		font-size: 3rem;
		color: #8B95A2;
	}
}
.batch__status{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.bi,.ico{
		font-size: 1.75rem;
	}
	span{
		font-size: 1rem;
		font-weight: 500;
		margin-right: .5rem;
	}
	&.c-danger{
		color: $color-danger;
	}
}
.border{
	&-bottom{
		border-bottom: solid 1px #f5f5f5;
	}
}
.subtitle{
	font-size: 1rem!important;
}

.plan{
	display: flex;
	align-items: center;
	padding:1.5rem;
	gap: 1.5rem;
	&__header{
		display: flex;
		flex-direction: column;
	}
}
.bg{
	&-gray{
		background-color: #E7EAF3;
	}
	&-gray-light{
		background-color: #F8F9FB;
	}
}

.line{
  border-color:#FAFAFA;
  opacity: .25;
}

.photo-dots{
  display: flex;
  align-items: center;
  gap: .25rem;
	li{
		width: .5rem;
		height: .5rem;
		border-radius: .5rem;
		background-color: #377DFF;
		&.clear{
			background-color:#ECF2FF!important;
		}
	}
}

.card__title{
  text-align: left;
  font: normal normal 300 21px/28px Roboto;
  letter-spacing: 0px;
  color: #8B95A2;
  opacity: 1;
}
.photo{
  display: flex;
  align-items: center;
  gap: .5rem;
}

.photo .bi{
  font-size: 1.5rem;
}
.photo span{
  color: #7897AB;
  font-size: .75rem;
}

.card__status{
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.flex-fill-50{
  flex-basis: 50%;
}
.repeat{
	background-color: $color-second;
	color: #FFFFFF;
	width: .75rem;
	height: .75rem;
	line-height: .75rem;
	border-radius: .75rem;
	display: inline-block;
	text-align: center;
}
