.sidebar{
	max-width: 17.5rem;
	width: 17.5rem;
	direction: ltr;
	background-color: $color-third;
	color: $white;
	transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	overflow: hidden;
	overflow-y: auto;
	height: 100vh;
	flex-basis: 17.5rem;
	&__search{
		background-image: url('./assets/images/bg-search.svg');
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 14rem;
		padding-top: 1.5rem;
		padding-bottom: 2.5rem;
		background-color: #2D3D53;
		padding-left: 1.875em;
		padding-right: 1.875em;
	}
	&__input{
		background-color: #ECF2FF;
		border-radius: 1.375rem;
		border: none;
		background-image: url('./assets/images/ico-search.svg');
		background-position: left 1rem center;
		background-repeat: no-repeat;
		width: 100%;
		height: 2.3125rem;
		padding-left: 2.8125rem;

		&:focus{
			outline: none;
			background-color: $white;
		}
		&::placeholder{
			color: #8B95A2;
			font-size: 1rem;
		}
	}
	&__nav{
		background-image: url('./assets/images/bg-side-nav.svg');
		background-position: bottom -2.5rem center;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #1E2F46;
		// min-height: 14rem;
		padding-bottom: 7rem;
		flex-grow: 1;
		@media screen and (min-width:1680px) {
			background-position: bottom center;
		}
	}
	&__list{
		display: flex;
		flex-direction: column;
		position: relative;
	}
	&__item{
		margin-bottom: 0;
		padding-left: 1.5em;
		padding-right: 1.5em;
		position: relative;
		display: flex;
		align-items: center;
	}
	&__footer{
		background-color: #1E2F46;
		img{
			width: 100%;
			height: auto;
		}
	}
	&__link{
		display: flex;
		align-items: center;
		font-size: 1rem;
		color:$white;
		z-index: 1;
		padding-top: 1rem;
		padding-bottom: 1rem;
		&::before{
			transition: all .25s ease-in-out;
			content: '';
			position: absolute;
			z-index: 0;
			width: .25rem;
			height: 100%;
			left: 0;
			top: 0;
		}
		span,em
		span,i{
			position: relative;
			z-index: 2;
			&::before{
				position: relative!important;
			}
		}
		i,em{
			margin-right: .75rem;
			min-width: 1rem;
			color: $white;
		}
		&.active{
			&::before{
				background-color: $white;
			}
			em{
				color: $white;
				&::before{
					color: $white;
				}
			}
		}
		&:hover{
			text-decoration: none;
			color:$color-third;
			i,em{
				color:$color-third;
				&::before{
					position: relative!important;
					color:$color-third;
				}
			}
			&::before{
				background-color: $white;
				width: 90%;
				border-radius: 0 2rem 2rem 0;
			}
		}
	}
	&.collapsed{
		margin-left: -17.5rem;
	}
	@media screen and (max-width:$md) {
		margin-left: -17.5rem;
		&.collapsed{
			margin-left: 0;
		}
	}
}
