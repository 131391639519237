.msn{
	display: flex;
	&--vertical{
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	&__title{
		font-size: 1.75rem;
		line-height: 120%;
		display: inline-block;
		text-align: center;
		@media screen and (max-width:$md) {
			font-size: 1.25rem;
		}
	}
	&__icon{
		font-size: 4rem;
		margin-bottom: 1rem;
		@media screen and (max-width:$md) {
			font-size: 2.5rem;
		}
	}
	&__text{
		color: #6F747B;
	}
	&--warning{
		.msn__icon{
			color: $color-warning;
		}
	}
	&--success{
		.msn__icon{
			color: $color-success;
		}
	}
}
