.grid{
	display: grid;
	&--checkboxes{
		gap: 1.25rem;
		grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 15rem)'}, 1fr));
	}
	&--descriptions{
		gap: 1.25rem;
		grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 10rem)'}, 1fr));
	}
	&--form{
		gap: 1.25rem;
		grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 10rem)'}, 1fr));
	}
	&--analysis{
		gap: 1.5rem;
		grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 18rem)'}, 1fr));
	}
	&--gallery{
		gap: 1.5rem;
		grid-template-columns: repeat(auto-fill, minmax(#{'min(100%, 6rem)'}, 1fr));
	}
}
.force-all-columns{
	grid-column: 1 / -1;
}
