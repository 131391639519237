.filters{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	flex-wrap: wrap;
	@media screen and (max-width:$md) {
		&>*{
			flex-grow: 1;
		}
	}
}
