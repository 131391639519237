.block{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 2em;
	&__title{
		font-size: 2.5rem!important;
		font-weight: 700!important;
		@media screen and (max-width:$md) {
			font-size: 1.75rem!important;
		}
	}
	&--rounded{
		border-radius: .5rem;
	}
	&--success{
		background-color: $color-success;
		color: $white;
	}
}
