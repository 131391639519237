@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
/* root */
.ng-select {
    min-width: 12rem;
}
.ds-modal {
    max-height: 90vh;
}
.ds-modal .mat-dialog-container {
    height: auto;
}
.ds-modal-lg .mat-dialog-container {
    padding: 0;
}
@media screen and (max-width: 576px) {
    .ds-modal-lg {
        max-width: inherit !important;
        width: 100%;
        max-height: 95vh;
        bottom: 0;
        position: fixed !important;
        padding-top: 3.3125rem;
        height: 100%;
    }
    .ds-modal-lg .mat-dialog-container {
        border-radius: 0;
    }
}
.ds-submodal {
    width: 90%;
    max-width: 24rem !important;
}
.ds-submodal .mat-dialog-container {
    padding: 0;
}
.ds-submodal .mat-dialog-container .modal__content,
.ds-submodal .mat-dialog-container .modal__header {
    padding: 1.5rem;
}
.ds-submodal .mat-dialog-container .modal__header {
    padding-bottom: 0;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .ds-modal-lg {
        width: 100%;
        max-height: 95vh;
    }
}
@media screen and (min-width: 768px) {
    .ds-modal .mat-dialog-container {
        min-width: 30rem;
        max-width: 38rem;
    }
    .ds-modal-lg .mat-dialog-container {
        min-width: 43.625rem;
        padding: 1.5rem;
        padding-top: 0;
        height: initial !important;
    }
}
@media screen and (min-width: 992px) {
    .ds-modal .mat-dialog-container {
        min-width: 30rem;
        max-width: 58rem;
    }
}

.ds-modal--sm .mat-dialog-container {
    min-width: 22rem !important;
    max-width: 28rem !important;
    max-height: 90vh;
}
@media screen and (max-width: 567px) {
    .ds-modal--sm .mat-dialog-container {
        min-width: inherit !important;
        max-width: 100% !important;
    }
}
.ds-modal--sm .mat-dialog-container .uploader-wrapper {
    width: 100%;
}
.no-click {
    pointer-events: none;
}
.no-click:hover {
    cursor: default;
    text-decoration: none;
}
.cdk-overlay-backdrop.mat-overlay-transparent-backdrop,
.cdk-overlay-backdrop-showing.no-showing {
    background-color: inherit !important;
    backdrop-filter: inherit !important;
}
.cdk-overlay-backdrop-showing {
    background-color: rgba(30, 47, 70, 0.95);
    backdrop-filter: blur(5px);
}

.mat-tab-label.mat-tab-label-active {
    color: #0045a2;
}
.mat-tab-label {
    opacity: 1 !important;
}
.cdk-drag.cdk-drag-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: none;
}
.cdk-drag-preview {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag.cdk-drag-preview td {
    border: none;
}
.mat-tab-body-content {
    padding: 1px;
}

input[type="date"],
input[type="time"] {
    font-family: var(--ds-font-family) !important;
}
.link.disabled,
.link[disabled] {
    color: #97989d;
    cursor: default;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--ds-color-four);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(148, 182, 247);
}
.mat-tab-label.mat-tab-label-active {
    color: var(--ds-color-four);
}
.mat-slide-toggle.mat-success.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffffff;
    /* border: solid 1px #4BED90; */
}
.mat-slide-toggle.mat-success.mat-checked .mat-slide-toggle-bar {
    background-color: #4bed90;
}
.mat-tab-label.mat-tab-label-active {
    color: var(--ds-color-four);
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: var(--ds-color-four);
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--ds-color-four);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--ds-color-four);
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: var(--ds-color-four);
}

.searchbox {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem;
    min-height: 2.625rem;
    border-radius: 2.625rem;
    gap: 0.5rem;
}
.searchbox input {
    min-width: inherit;
}
.searchbox--button {
    border: none;
    /* background-color: #8B95A2; */
    background-color: #377dff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    padding: 0.25rem;
    border-radius: 1.25rem;
}
.searchbox--button:hover {
    cursor: pointer;
}
.searchbox--toggle {
    display: flex;
    gap: 0.25rem;
    color: #8b95a2;
}
.searchbox--toggle em.active {
    color: #377dff;
}
.searchbox input {
    max-width: 10rem;
    border: none;
    letter-spacing: normal;
    color: #8b95a2;
}
.searchbox input:focus {
    border: none;
    outline: none;
}
.ng-dropdown-panel.ng-select-multiple.ng-select.ng-select-full-options.ng-select-bottom {
    width: auto !important;
}

.mat-progress-bar.mat-copec .mat-progress-bar-primary.mat-progress-bar-fill::after {
    background-color: #1e2f46 !important;
}
.photo-dots li {
    background-color: #1e2f46;
}
.mat-header-cell {
    text-transform: uppercase;
    line-height: 100%;
}
.ng-placeholder {
    text-transform: capitalize !important;
}
.cursor-default:hover {
    background-color: initial !important;
    color: initial;
    cursor: default !important;
}

.btn--first.btn--outline.cursor-default {
    color: #377dff;
}

.link.link--icon.cursor-default {
    color: #377dff;
}

.modal__header.is-sticky {
    padding: 1.357rem 1.357rem 0;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
}
