.definition{
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
	color: #1E2F46;
	&__visual{
		min-width: 2.25rem;
		margin-right: 1rem;
		i,em{
			font-size: 2.25rem;
			&.ico--big{
				font-size: 2.5rem;
			}
		}
	}
	&__terms{
		display: flex;
		flex-direction: column;
		small{
			font-size: .875rem;
			line-height: 100%;
			margin-bottom: .5rem;
		}
		strong{
			font-size: 1rem;
			line-height: 120%;
		}
		span{
			font-size: 1rem;
			line-height: 120%;
		}
	}
	&:last-child{
		margin-bottom: 0;
	}
	&--vertical{
		flex-direction: column;
		align-items: center;
		margin-left: 1rem;
		margin-right: 1rem;
		@media screen and (min-width:$md) {
			flex-basis: 33%;
		}
		.definition__terms{
			align-items: center;
		}
		.definition__visual{
			margin-right: 0;
			margin-bottom: .5rem;
			min-width: inherit;
			i,em{
				font-size: 1.5rem;
				&.ico--big{
					font-size: 2.5rem;
				}
			}
			@media screen and (max-width:$md) {
				margin-bottom: 0;
				margin-right: .5rem;
			}
		}
		@media screen and (max-width:$md) {
			flex-direction: row;
			margin-left: inherit;
			margin-right: inherit;
		}
	}
}
