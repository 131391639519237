.table{
	border-collapse: 0;
	border-spacing: 0;
	&--simple{
		box-shadow: 0px 8px 12px -7px rgba(0,0,0,.12);
		thead{
			tr{
				th{
					border-bottom: 1px solid #E4EAEF;
					padding: .75rem;
					vertical-align: bottom;
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px solid #E4EAEF;
				&:last-child{
					border-bottom: none;
				}
				td{
					vertical-align: bottom;
					background-color: $white;
					text-align: center;
					padding: .75rem;
					border-bottom: 1px solid #E4EAEF;
					&:first-child{
						border-left: 1px solid #E4EAEF;
					}
					&:last-child{
						border-right: 1px solid #E4EAEF;
					}
				}
			}
		}
	}
	&--highlight{
		border: 4px solid #0045a2;
		thead{
			tr{
				th{
					background-color: #0045a2;
					padding: .5rem;
					color: #fff;
					text-align: left;
					&:first-child {
						width: 20%;
					}
				}
			}
		}
		tbody{
			tr{
				td{
					span{
						line-height: 120%;
					}
				}
			}
		}
	}
	&--basic{
		border: 1px solid #E4EAEF;
		thead{
			tr{
				th{
					border: 1px solid #E4EAEF;
					background-color: #E7EAF3;
				}
			}
		}
		tbody{
			tr{
				td{
					border: 1px solid #E4EAEF;
				}
			}
		}
	}
	&--striped{
		thead{
			tr{
				th{
					border-bottom: 1px solid #E4EAEF;
					padding: .5rem;
					vertical-align: bottom;
				}
			}
		}
		tbody{
			tr{
				&:nth-child(odd){
					td{
						background-color: #EDF2FE;
					}
				}
				td{
					padding: .5rem;
				}
			}
		}
	}
}
.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
