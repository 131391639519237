.list{
	&--links{
		li{
			display: block;
		}
		a{
			color: $color-action;
			line-height: 100%;
			font-size: .75rem;
		}
	}
}
