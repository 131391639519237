.badge{
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 100%;
	font-weight: 500;
	border: none;
	.bi{
		font-size: 1.5rem;
		line-height: 100%;
	}
	&--status{
		font-size: .75rem;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .25rem .5rem;
		border-radius: 1rem;
		background-color: $color-third;
		color: $white;
		min-width: 1.875rem;
	}
	&--position{
		width:2rem;
		height: 2rem;
		border-radius: 2rem;
		background-color: $white;
		color:$color-first;
	}
	&--success{
		background-color: $color-success;
		color: $white;
	}
	&--warning{
		background-color: $color-warning;
		color: $white;
	}
	&--danger{
		background-color: $color-danger;
		color: $white;
	}
	&--light{
		background-color: #EFF1FE;
		color: #494B5A;
	}
	&--test{
		padding: .25rem .5rem;
		border-radius: .25rem;
		line-height: 100%;
		background-color: #1E2F46;
		color: #ffffff;
		min-height: 1.625rem;
		margin-top: .25rem;
		gap: .25rem;
		.bi{
			font-size:1rem!important;
			&.bi-clock-fill{
				font-size:.75rem!important;
			}
		}
	}
	&--first{
		background-color: #377DFF;
		color: #ffffff;
	}
	&--orange{
		background-color: #FF9300;
		color: #ffffff;
	}
}
a.badge:hover,
button.badge:hover{
	cursor: pointer;
}
