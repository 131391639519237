.header{
	background-color: $white;
	display: flex;
	justify-content: space-between;
	padding: 1rem 1.5625rem;
	box-shadow: 0 0 2rem 0 rgba(#2125291a, .1);
	position: sticky;
	&__btn{
		font-size: 1.5rem;
	}
}

.user{
	display: flex;
	align-items: center;
	&__name{
		margin-right: .5rem;
		font-size: .875rem;
		text-transform: capitalize;
		font-weight: 600;
	}
	&:hover{
		text-decoration: none;
	}
}
