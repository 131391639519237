.alert{
	display: flex;
	background-color: #EFF0F1;
	color: #4F6D7C;
	border-radius: .5rem;
	font-size: .875rem;
	align-items: center;
	padding: 1em;
	flex-wrap: wrap;
	&--info{
		background-color: #F1F5F8;
		color: #6F98AD;
	}
	&--warning{
		background-color: #FCEECC;
		color:#4F6D7C;
	}
	&__figure{
		padding: 0;
		margin: 0 1rem 0 0;
		font-size: 2rem;
		align-self: flex-start;
		// flex-grow: 1;
	}
	&__message{
		flex-grow: 1;
		flex-basis: 0;
	}
	&--success{
		background-color: #1DB117;
		color:#FFF;
	}
	&--info_dark{
		background-color: #597EAA;
		color:#FFF;
	}
	&--warning_dark{
		background-color: #FB9F26;
		color:#FFF;
	}
	&--danger{
		background-color: #BE3B31;
		color:#FFF;
	}
}

.empty{
	background-color: #F2F5F8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border-radius: .5rem;
	color: #8B95A2;
	gap: 1rem;
	min-height: 50vh;
	span{
		font-size: 1rem;
		line-height: 120%;
	}
}
