/* Vars */
@import url(../node_modules/bootstrap-icons/font/bootstrap-icons.css);
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
:root {
  --ds-color-first:  #0045A2;
  --ds-color-second: #D9232A;
  --ds-color-third:  #1E2F46;
  --ds-color-four:  #377DFF;
  --ds-font-family:  Roboto, arial, sans-serif; }

/* functions */
/* External*/
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #FFF !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* Base */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0; }

body {
  font-family: "Roboto", arial, sans-serif;
  font-size: 16px;
  color: #000;
  background-color: #FAFAFA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0; }

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }

a,
button,
input,
select {
  transition-property: transform, background, background-color, border-color, color;
  transition-duration: 0.3s; }

textarea {
  resize: vertical; }

a {
  color: #000;
  text-decoration: none; }
  a:hover {
    color: #0045A2;
    text-decoration: underline; }
  a:focus {
    outline: none; }

img {
  vertical-align: middle; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

[role="button"] {
  cursor: pointer; }

[hidden] {
  display: none !important; }

#slick-areas {
  margin-bottom: 0; }

main {
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
  overflow: hidden;
  flex-direction: column; }

/* Components */
.landing {
  background-color: var(--ds-color-third);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  min-height: 100vh;
  color: #FFF; }
  .landing__header {
    flex: 0 1 auto;
    align-self: flex-start;
    width: 50%;
    max-width: 34rem;
    min-width: 20rem; }
    .landing__header img {
      width: 100%; }
  .landing__content {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 80%;
    max-width: 50ch;
    text-align: center;
    flex-direction: column; }
    .landing__content h2 {
      font-size: 2.4rem;
      font-weight: 100;
      margin-bottom: 2rem; }
      @media screen and (max-width: 768px) {
        .landing__content h2 {
          font-size: 2rem; } }
    .landing__content a {
      align-self: center; }
  .landing__footer {
    flex: 0 1 auto;
    align-self: flex-end;
    width: 25%;
    max-width: 18rem;
    min-width: 10rem;
    flex-grow: .5;
    display: flex;
    align-items: flex-end; }
    .landing__footer img {
      width: 100%; }

.btn {
  padding: 0.6875em 3.125em;
  border-radius: .25em;
  font-size: 1.125rem;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  font-weight: 500;
  text-transform: uppercase; }
  .btn--light {
    background-color: #FFF;
    color: #4F6D7C; }
    .btn--light.btn--outline {
      border: solid 1px #4F6D7C; }
    .btn--light:hover {
      background-color: #0045A2;
      color: #FFF;
      text-decoration: none; }
  .btn--small {
    padding: .625em 0.875em;
    font-size: 0.875rem; }
  .btn--first {
    background-color: #377DFF;
    color: #FFF; }
    .btn--first.btn--text {
      color: #377DFF;
      background-color: transparent; }
      .btn--first.btn--text:hover {
        background-color: transparent;
        color: #0045A2; }
    .btn--first.btn--outline {
      background-color: transparent;
      border: solid 1px #377DFF;
      color: #377DFF; }
    .btn--first:hover {
      background-color: #0045A2;
      color: #FFF; }
  .btn--text {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0; }
    .btn--text.btn--icon i, .btn--text.btn--icon em {
      font-size: .8rem;
      height: initial;
      width: initial;
      overflow: inherit; }
      .btn--text.btn--icon i::before, .btn--text.btn--icon em::before {
        margin-top: inherit;
        margin-left: inherit; }
  .btn--second {
    background-color: #6F98AD;
    color: #FFF; }
    .btn--second:hover {
      color: #FFF;
      background-color: #4F6D7C; }
  .btn--success {
    background-color: #1DB117;
    color: #FFF; }
    .btn--success:hover {
      color: #FFF;
      background-color: #198D14; }
  .btn--danger {
    background-color: #FF7E83;
    color: #FFF; }
    .btn--danger:hover {
      color: #FFF;
      background-color: #D9232A; }
  .btn--icon {
    display: flex;
    align-items: center; }
    .btn--icon i, .btn--icon em {
      height: 1rem;
      width: 1rem;
      overflow: hidden;
      font-size: 1.5rem;
      display: flex;
      line-height: 100%;
      margin-right: .5rem; }
      .btn--icon i::before, .btn--icon em::before {
        margin-top: -.25rem;
        margin-left: -.25rem; }
  .btn--only-icon {
    padding: 0; }
  .btn:hover {
    cursor: pointer;
    text-decoration: none; }
  .btn[disabled] {
    background-color: #E7EAF3 !important;
    color: #97989d !important; }
    .btn[disabled]:hover {
      cursor: default !important; }
    .btn[disabled]:focus {
      outline: none !important; }

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  font-weight: 500;
  border: none; }
  .badge .bi {
    font-size: 1.5rem;
    line-height: 100%; }
  .badge--status {
    font-size: .75rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem .5rem;
    border-radius: 1rem;
    background-color: #1E2F46;
    color: #FFF;
    min-width: 1.875rem; }
  .badge--position {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: #FFF;
    color: #0045A2; }
  .badge--success {
    background-color: #1DB117;
    color: #FFF; }
  .badge--warning {
    background-color: #FCC230;
    color: #FFF; }
  .badge--danger {
    background-color: #FF7E83;
    color: #FFF; }
  .badge--light {
    background-color: #EFF1FE;
    color: #494B5A; }
  .badge--test {
    padding: .25rem .5rem;
    border-radius: .25rem;
    line-height: 100%;
    background-color: #1E2F46;
    color: #ffffff;
    min-height: 1.625rem;
    margin-top: .25rem;
    gap: .25rem; }
    .badge--test .bi {
      font-size: 1rem !important; }
      .badge--test .bi.bi-clock-fill {
        font-size: 0.75rem !important; }
  .badge--first {
    background-color: #377DFF;
    color: #ffffff; }
  .badge--orange {
    background-color: #FF9300;
    color: #ffffff; }

a.badge:hover,
button.badge:hover {
  cursor: pointer; }

.sidebar {
  max-width: 17.5rem;
  width: 17.5rem;
  direction: ltr;
  background-color: #1E2F46;
  color: #FFF;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
  flex-basis: 17.5rem; }
  .sidebar__search {
    background-image: url("./assets/images/bg-search.svg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 14rem;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    background-color: #2D3D53;
    padding-left: 1.875em;
    padding-right: 1.875em; }
  .sidebar__input {
    background-color: #ECF2FF;
    border-radius: 1.375rem;
    border: none;
    background-image: url("./assets/images/ico-search.svg");
    background-position: left 1rem center;
    background-repeat: no-repeat;
    width: 100%;
    height: 2.3125rem;
    padding-left: 2.8125rem; }
    .sidebar__input:focus {
      outline: none;
      background-color: #FFF; }
    .sidebar__input::placeholder {
      color: #8B95A2;
      font-size: 1rem; }
  .sidebar__nav {
    background-image: url("./assets/images/bg-side-nav.svg");
    background-position: bottom -2.5rem center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1E2F46;
    padding-bottom: 7rem;
    flex-grow: 1; }
    @media screen and (min-width: 1680px) {
      .sidebar__nav {
        background-position: bottom center; } }
  .sidebar__list {
    display: flex;
    flex-direction: column;
    position: relative; }
  .sidebar__item {
    margin-bottom: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
    position: relative;
    display: flex;
    align-items: center; }
  .sidebar__footer {
    background-color: #1E2F46; }
    .sidebar__footer img {
      width: 100%;
      height: auto; }
  .sidebar__link {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #FFF;
    z-index: 1;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .sidebar__link::before {
      transition: all .25s ease-in-out;
      content: '';
      position: absolute;
      z-index: 0;
      width: .25rem;
      height: 100%;
      left: 0;
      top: 0; }
    .sidebar__link span, .sidebar__link em
span, .sidebar__link i {
      position: relative;
      z-index: 2; }
      .sidebar__link span::before, .sidebar__link em
span::before, .sidebar__link i::before {
        position: relative !important; }
    .sidebar__link i, .sidebar__link em {
      margin-right: .75rem;
      min-width: 1rem;
      color: #FFF; }
    .sidebar__link.active::before {
      background-color: #FFF; }
    .sidebar__link.active em {
      color: #FFF; }
      .sidebar__link.active em::before {
        color: #FFF; }
    .sidebar__link:hover {
      text-decoration: none;
      color: #1E2F46; }
      .sidebar__link:hover i, .sidebar__link:hover em {
        color: #1E2F46; }
        .sidebar__link:hover i::before, .sidebar__link:hover em::before {
          position: relative !important;
          color: #1E2F46; }
      .sidebar__link:hover::before {
        background-color: #FFF;
        width: 90%;
        border-radius: 0 2rem 2rem 0; }
  .sidebar.collapsed {
    margin-left: -17.5rem; }
  @media screen and (max-width: 768px) {
    .sidebar {
      margin-left: -17.5rem; }
      .sidebar.collapsed {
        margin-left: 0; } }

.suggestion {
  background-color: #377DFF;
  color: #FFF;
  padding: .5rem;
  align-items: center;
  display: flex;
  gap: 1rem;
  border-radius: .5rem;
  font-size: .875rem; }
  .suggestion span {
    line-height: 120%; }
  .suggestion .bi {
    font-size: 1.5rem; }

.header {
  background-color: #FFF;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5625rem;
  box-shadow: 0 0 2rem 0 rgba(33, 37, 41, 0.1);
  position: sticky; }
  .header__btn {
    font-size: 1.5rem; }

.user {
  display: flex;
  align-items: center; }
  .user__name {
    margin-right: .5rem;
    font-size: .875rem;
    text-transform: capitalize;
    font-weight: 600; }
  .user:hover {
    text-decoration: none; }

.content {
  padding: 1.5625rem;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }
  .content__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 2rem; }
  .content__letterhead {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .content__letterhead > * {
      line-height: 110%; }
  .content__title {
    flex-grow: 1; }
    .content__title h2 {
      font-size: 1.3125rem;
      font-weight: 400; }
    @media screen and (max-width: 992px) {
      .content__title {
        margin-bottom: 1rem; } }
  .content__tracking {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .content__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap; }
  @media screen and (max-width: 768px) {
    .filters > * {
      flex-grow: 1; } }

.form--sequence {
  display: flex;
  justify-content: space-between; }
  .form--sequence input {
    width: 2.25rem;
    height: 3rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: .3125rem;
    border: solid 1px #8B95A2; }
    @media screen and (max-width: 768px) {
      .form--sequence input {
        width: 1.5rem;
        height: 2.2rem; } }

.form__row {
  display: flex;
  flex-direction: row; }

.form__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; }

.input {
  display: block;
  width: 100%;
  padding: .25rem .7rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .2rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.input--search {
  background-color: #FFF;
  border: 1px solid #ced4da;
  font-size: .875rem;
  padding: .75em .75em .75em 3em;
  border-radius: 2rem;
  background-image: url("./assets/images/ico-search.svg");
  background-position: left 1rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 50%;
  max-width: 16rem; }
  @media screen and (max-width: 768px) {
    .input--search {
      width: 100%; } }
  .input--search:focus {
    outline: none;
    border-color: #0045A2; }
  .input--search.input--align_end {
    padding: .75em 3em .75em .75rem;
    background-position: right 0 center;
    text-align: right;
    transition: all .3s ease-in-out; }
    .input--search.input--align_end:focus {
      background-position: right 1rem center;
      padding: .75em 3em .75em .75rem;
      text-align: left; }

.input--clear {
  border: none !important;
  background-color: transparent; }
  .input--clear:focus {
    background-color: #FFF; }

.input--text {
  padding: .5rem .5rem;
  border: solid 1px #CFD4D9;
  min-height: 36px;
  line-height: 100%; }

.input--large {
  text-align: center;
  letter-spacing: .5rem;
  font-size: 2.5rem;
  font-weight: 800;
  padding: .25rem;
  line-height: 100%; }

.uploader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 1px #97989d;
  border-radius: .25rem;
  padding: 2rem;
  position: relative;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .uploader-wrapper {
      width: 30rem; } }
  .uploader-wrapper .bi {
    color: #E7EAF3;
    font-size: 3rem;
    margin-bottom: 1rem; }
  .uploader-wrapper input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .uploader-wrapper h3 {
    font-size: 1rem !important;
    margin: 0 !important;
    line-height: 100% !important; }

.files-list {
  margin-top: 1.5rem; }
  .files-list .single-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    display: flex;
    flex-grow: 1; }
    .files-list .single-file .delete {
      display: flex;
      margin-left: 1rem;
      cursor: pointer;
      align-self: flex-end; }
      .files-list .single-file .delete .bi {
        color: #D9232A;
        font-size: 1.5rem; }
    .files-list .single-file .name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0; }
    .files-list .single-file .size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem; }
    .files-list .single-file .percents {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
      position: absolute;
      right: 0;
      top: -1.25rem; }
    .files-list .single-file .file-info {
      flex: 1; }
    .files-list .single-file .file-icon {
      margin-right: 1rem; }
      .files-list .single-file .file-icon .bi {
        font-size: 2.5rem;
        color: #007c02; }

.progress-cont {
  height: 7px;
  width: 100%;
  border-radius: 4px;
  background-color: #d0d0d0;
  position: relative; }
  .progress-cont .progress {
    width: 0;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 4px;
    background-color: #0045A2;
    transition: 0.5s all; }

textarea {
  padding: .5rem .5rem;
  border: solid 1px #CFD4D9;
  min-height: 4.5rem;
  line-height: 100%;
  display: block;
  resize: none;
  width: 100%;
  border-radius: .2rem; }

.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .modal__contanier {
    background-color: #FFF; }
  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
    margin-bottom: 1rem; }
    .modal__header.is-sticky {
      padding: 1.357rem 1.357rem 0; }
      @media screen and (max-width: 576px) {
        .modal__header.is-sticky {
          position: absolute;
          border-radius: 1.5rem 1.5rem 0 0;
          top: 0;
          left: 0;
          width: 100%;
          background-color: #FFF; } }
    .modal__header .mat-typography,
    .modal__header h2 {
      font-size: 1.25rem !important;
      font-weight: 300 !important;
      margin: 0 !important;
      max-width: 80%; }
      .modal__header .mat-typography.modal__title,
      .modal__header h2.modal__title {
        font-weight: 500 !important;
        font-size: 1.5rem !important; }
  .modal__foot {
    display: flex;
    padding-top: 1.5rem; }

.alert {
  display: flex;
  background-color: #EFF0F1;
  color: #4F6D7C;
  border-radius: .5rem;
  font-size: .875rem;
  align-items: center;
  padding: 1em;
  flex-wrap: wrap; }
  .alert--info {
    background-color: #F1F5F8;
    color: #6F98AD; }
  .alert--warning {
    background-color: #FCEECC;
    color: #4F6D7C; }
  .alert__figure {
    padding: 0;
    margin: 0 1rem 0 0;
    font-size: 2rem;
    align-self: flex-start; }
  .alert__message {
    flex-grow: 1;
    flex-basis: 0; }
  .alert--success {
    background-color: #1DB117;
    color: #FFF; }
  .alert--info_dark {
    background-color: #597EAA;
    color: #FFF; }
  .alert--warning_dark {
    background-color: #FB9F26;
    color: #FFF; }
  .alert--danger {
    background-color: #BE3B31;
    color: #FFF; }

.empty {
  background-color: #F2F5F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: .5rem;
  color: #8B95A2;
  gap: 1rem;
  min-height: 50vh; }
  .empty span {
    font-size: 1rem;
    line-height: 120%; }

.list--links li {
  display: block; }

.list--links a {
  color: #377DFF;
  line-height: 100%;
  font-size: .75rem; }

.link {
  color: #377DFF;
  font-size: .875rem;
  background-color: transparent;
  border: none; }
  .link i, .link em {
    font-size: 1.5rem; }
  .link--icon {
    display: flex;
    align-items: center; }
  .link:hover {
    cursor: pointer;
    color: #0045A2; }
  .modal__foot .link {
    text-transform: uppercase; }

.definition {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  color: #1E2F46; }
  .definition__visual {
    min-width: 2.25rem;
    margin-right: 1rem; }
    .definition__visual i, .definition__visual em {
      font-size: 2.25rem; }
      .definition__visual i.ico--big, .definition__visual em.ico--big {
        font-size: 2.5rem; }
  .definition__terms {
    display: flex;
    flex-direction: column; }
    .definition__terms small {
      font-size: .875rem;
      line-height: 100%;
      margin-bottom: .5rem; }
    .definition__terms strong {
      font-size: 1rem;
      line-height: 120%; }
    .definition__terms span {
      font-size: 1rem;
      line-height: 120%; }
  .definition:last-child {
    margin-bottom: 0; }
  .definition--vertical {
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem; }
    @media screen and (min-width: 768px) {
      .definition--vertical {
        flex-basis: 33%; } }
    .definition--vertical .definition__terms {
      align-items: center; }
    .definition--vertical .definition__visual {
      margin-right: 0;
      margin-bottom: .5rem;
      min-width: inherit; }
      .definition--vertical .definition__visual i, .definition--vertical .definition__visual em {
        font-size: 1.5rem; }
        .definition--vertical .definition__visual i.ico--big, .definition--vertical .definition__visual em.ico--big {
          font-size: 2.5rem; }
      @media screen and (max-width: 768px) {
        .definition--vertical .definition__visual {
          margin-bottom: 0;
          margin-right: .5rem; } }
    @media screen and (max-width: 768px) {
      .definition--vertical {
        flex-direction: row;
        margin-left: inherit;
        margin-right: inherit; } }

.msn {
  display: flex; }
  .msn--vertical {
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .msn__title {
    font-size: 1.75rem;
    line-height: 120%;
    display: inline-block;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .msn__title {
        font-size: 1.25rem; } }
  .msn__icon {
    font-size: 4rem;
    margin-bottom: 1rem; }
    @media screen and (max-width: 768px) {
      .msn__icon {
        font-size: 2.5rem; } }
  .msn__text {
    color: #6F747B; }
  .msn--warning .msn__icon {
    color: #FCC230; }
  .msn--success .msn__icon {
    color: #1DB117; }

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2em; }
  .block__title {
    font-size: 2.5rem !important;
    font-weight: 700 !important; }
    @media screen and (max-width: 768px) {
      .block__title {
        font-size: 1.75rem !important; } }
  .block--rounded {
    border-radius: .5rem; }
  .block--success {
    background-color: #1DB117;
    color: #FFF; }

.card {
  position: relative; }
  .card--request {
    display: flex;
    padding: 1rem;
    flex-direction: column; }
    .card--request .card__title {
      color: #1E2F46;
      font-size: 1.125rem;
      padding: 0 0 .25rem;
      font-weight: 600; }
      .card--request .card__title .bi {
        margin-right: .5rem; }
    .card--request .card__subtitle {
      color: #8B95A2;
      font-weight: 400;
      display: flex;
      align-items: center; }
      .card--request .card__subtitle .bi, .card--request .card__subtitle .ico {
        margin-right: .5rem; }
    .card--request .card__header {
      display: flex; }
    .card--request .card__content {
      border-top: solid 1px #E7EAF3;
      padding-top: 1rem; }
    .card--request .card__column {
      display: flex;
      flex-direction: column; }
  .card.is-highlight {
    border: solid 1px #1E2F46; }
    .card.is-highlight .badge--position {
      background-color: #1E2F46;
      color: #FFF; }
  .card .badge--position {
    position: absolute;
    right: 1rem;
    top: 1rem; }
  .card__jar_status {
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 9rem), 1fr)); }
  .card__header.is-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); }
  .card--batch .card__title {
    display: flex;
    align-items: center;
    gap: .75rem; }
  .card--batch .card__figure {
    background-color: #EEE1CE;
    margin: 0;
    color: #A8650A;
    line-height: 100%;
    border-radius: .3125rem;
    padding: .5rem; }
    .card--batch .card__figure .bi, .card--batch .card__figure .ico {
      line-height: 100%; }
  .card--batch .card__info {
    display: flex;
    flex-direction: column; }
    .card--batch .card__info strong {
      color: #A8650A;
      font-size: 1.5625rem;
      line-height: 100%;
      font-weight: bold; }
    .card--batch .card__info small {
      color: #7897AB;
      font-size: .75rem;
      line-height: 100%; }
  .card--batch .card__progress small {
    color: #7897AB;
    font-size: .75rem;
    line-height: 100%; }
  .card--batch .card__amount {
    font-size: 1.5625rem;
    line-height: 100%;
    color: #1E2F46;
    display: block; }
  .card--analysis .card__title {
    display: flex;
    align-items: center;
    gap: .75rem; }
  .card--analysis .card__figure {
    background-color: #E5D7E4;
    margin: 0;
    color: #813E79;
    line-height: 100%;
    border-radius: .3125rem;
    padding: .5rem; }
    .card--analysis .card__figure .bi, .card--analysis .card__figure .ico {
      line-height: 100%; }
  .card--analysis .card__info {
    display: flex;
    flex-direction: column; }
    .card--analysis .card__info strong {
      color: #813E79;
      font-size: 1.25rem;
      line-height: 100%;
      font-weight: bold; }
    .card--analysis .card__info small {
      color: #7897AB;
      font-size: .75rem;
      line-height: 100%; }
  .card--analysis .card__progress small {
    color: #7897AB;
    font-size: .75rem;
    line-height: 100%; }
  .card--analysis .card__amount {
    font-size: 1.5625rem;
    line-height: 100%;
    color: #1E2F46;
    display: block; }
  .card--sample .card__title {
    display: flex;
    align-items: center;
    gap: .75rem; }
  .card--sample .card__figure {
    background-color: #CCD4DE;
    margin: 0;
    color: #1E2F46;
    line-height: 100%;
    border-radius: .3125rem;
    padding: .5rem;
    min-width: 2.3125rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .card--sample .card__figure .bi, .card--sample .card__figure .ico {
      line-height: 100%; }
  .card--sample .card__info {
    display: flex;
    flex-direction: column; }
    .card--sample .card__info strong {
      color: #1E2F46;
      font-size: 1.25rem;
      line-height: 100%;
      font-weight: bold; }
    .card--sample .card__info small {
      color: #7897AB;
      font-size: .75rem;
      line-height: 100%; }
  .card--sample .card__progress small {
    color: #7897AB;
    font-size: .75rem;
    line-height: 100%; }
  .card--sample .card__amount {
    font-size: 1.5625rem;
    line-height: 100%;
    color: #1E2F46;
    display: block; }

.grid-blocks {
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 20rem), 1fr)); }

.grid {
  display: grid; }
  .grid--checkboxes {
    gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 15rem), 1fr)); }
  .grid--descriptions {
    gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 10rem), 1fr)); }
  .grid--form {
    gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 10rem), 1fr)); }
  .grid--analysis {
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 18rem), 1fr)); }
  .grid--gallery {
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 6rem), 1fr)); }

.force-all-columns {
  grid-column: 1 / -1; }

.table {
  border-collapse: 0;
  border-spacing: 0; }
  .table--simple {
    box-shadow: 0px 8px 12px -7px rgba(0, 0, 0, 0.12); }
    .table--simple thead tr th {
      border-bottom: 1px solid #E4EAEF;
      padding: .75rem;
      vertical-align: bottom; }
    .table--simple tbody tr {
      border-bottom: 1px solid #E4EAEF; }
      .table--simple tbody tr:last-child {
        border-bottom: none; }
      .table--simple tbody tr td {
        vertical-align: bottom;
        background-color: #FFF;
        text-align: center;
        padding: .75rem;
        border-bottom: 1px solid #E4EAEF; }
        .table--simple tbody tr td:first-child {
          border-left: 1px solid #E4EAEF; }
        .table--simple tbody tr td:last-child {
          border-right: 1px solid #E4EAEF; }
  .table--highlight {
    border: 4px solid #0045a2; }
    .table--highlight thead tr th {
      background-color: #0045a2;
      padding: .5rem;
      color: #fff;
      text-align: left; }
      .table--highlight thead tr th:first-child {
        width: 20%; }
    .table--highlight tbody tr td span {
      line-height: 120%; }
  .table--basic {
    border: 1px solid #E4EAEF; }
    .table--basic thead tr th {
      border: 1px solid #E4EAEF;
      background-color: #E7EAF3; }
    .table--basic tbody tr td {
      border: 1px solid #E4EAEF; }
  .table--striped thead tr th {
    border-bottom: 1px solid #E4EAEF;
    padding: .5rem;
    vertical-align: bottom; }
  .table--striped tbody tr:nth-child(odd) td {
    background-color: #EDF2FE; }
  .table--striped tbody tr td {
    padding: .5rem; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.txt--lg {
  font-size: 2rem !important; }

.txt--md {
  font-size: 1.5625rem !important; }

.txt--sm {
  font-size: 1rem !important; }

.txt--xs {
  font-size: 0.75rem !important; }

.txt--light {
  font-weight: 300 !important; }

.txt--normal {
  font-weight: 400 !important; }

.txt--black {
  font-weight: 900 !important; }

.txt--third {
  color: #1E2F46 !important; }

.txt--action {
  color: #377DFF !important; }

.txt--success {
  color: #1DB117 !important; }

.txt--white {
  color: #FFF !important; }

.txt--dark {
  color: #000 !important; }

.txt--vertical {
  writing-mode: tb-rl; }

.txt--warning {
  color: #ffc107 !important; }

.txt--danger {
  color: #dc3545 !important; }

.txt--gray {
  color: #8B95A2 !important; }

.txt--uppercase {
  text-transform: uppercase; }

.description {
  display: flex;
  flex-direction: column; }
  .description__term {
    font-size: 0.9375rem; }
  .description__definition {
    font-size: 0.9375rem;
    padding: 0;
    margin: 0;
    font-weight: 600; }

.mat-chip.ds-chip,
.ds-chip {
  font-size: 0.75rem;
  line-height: 100%;
  padding: .125em .675em;
  border: solid 2px #1E2F46;
  color: #1E2F46;
  border-radius: .5rem;
  font-weight: 600; }
  .mat-chip.ds-chip--danger,
  .ds-chip--danger {
    color: #D9232A;
    border-color: #D9232A; }
  .mat-chip.ds-chip--fill,
  .ds-chip--fill {
    border-radius: 1rem;
    border-color: transparent;
    font-weight: 400;
    background-color: #E7EAF3;
    color: #8B95A2; }
    .mat-chip.ds-chip--fill.ds-chip--danger,
    .ds-chip--fill.ds-chip--danger {
      color: #D9232A;
      background-color: #FFE8E9; }
    .mat-chip.ds-chip--fill.ds-chip--success,
    .ds-chip--fill.ds-chip--success {
      color: #1DB117;
      background-color: #E1FCE0; }
    .mat-chip.ds-chip--fill.ds-chip--warning,
    .ds-chip--fill.ds-chip--warning {
      color: #E38900;
      background-color: #FFF5E6; }
    .mat-chip.ds-chip--fill.ds-chip--gray,
    .ds-chip--fill.ds-chip--gray {
      color: #FFFFFF;
      background-color: #8B95A2; }
  .mat-chip.ds-chip--icon,
  .ds-chip--icon {
    display: flex;
    gap: .5rem;
    align-items: center; }
    .mat-chip.ds-chip--icon .bi,
    .ds-chip--icon .bi {
      font-size: 1.5em;
      line-height: 100%; }
    td .mat-chip.ds-chip--icon, td
    .ds-chip--icon {
      display: inline-flex; }
  .mat-chip.ds-chip--action,
  .ds-chip--action {
    color: #FFFFFF;
    background-color: #377DFF; }

.report {
  background-color: #ffffff;
  min-width: 60rem;
  max-width: 80rem;
  margin-inline: auto;
  padding: 2.625rem; }
  .report .card {
    flex-basis: 35%;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: .375rem;
    border: 1px solid #D3D3D3; }
    @media print {
      .report .card {
        box-shadow: none;
        padding: 1rem;
        border: solid 1px #333; } }
    .report .card .description {
      margin-bottom: 0; }
      .report .card .description .description__definition {
        margin-bottom: .5rem;
        letter-spacing: 1.1px;
        font-size: .6875rem;
        text-transform: uppercase; }
      .report .card .description .description__term {
        color: #666;
        font-size: .8125rem;
        line-height: 1.125rem; }
    .report .card:nth-child(2) {
      flex-basis: 25%; }
  .report .table {
    border-collapse: 0;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%; }
    .report .table tr {
      border-bottom: solid 1px #EFEFEF; }
      .report .table tr td {
        font-size: .8125rem;
        padding: .5rem;
        line-height: 100%;
        color: #707070; }
        .report .table tr td:first-child {
          color: #000; }
        .report .table tr td.col-highlight {
          border-left: 4px solid #0045a2;
          border-right: 4px solid #0045a2; }
      .report .table tr:first-child td.col-highlight {
        border-top: 4px solid #0045a2; }
      .report .table tr:last-child td.col-highlight {
        border-bottom: 4px solid #0045a2; }
    .report .table--detail thead tr {
      border-bottom: none; }
      .report .table--detail thead tr th {
        padding: .5rem;
        white-space: nowrap;
        font-size: 0.8125rem;
        font-weight: normal; }
        .report .table--detail thead tr th.col-highlight {
          border: 4px solid #0045a2;
          background-color: #0045a2;
          color: #fff; }
    .report .table--detail tbody {
      border: solid 1px #EFEFEF; }
      .report .table--detail tbody tr.border-bottom {
        border-width: 2px;
        border-color: #BCBCBC; }
      .report .table--detail tbody tr td.txt--vertical {
        border: solid 1px #EFEFEF;
        transform: rotate(-180deg); }
  .report__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem; }
    .report__top .black {
      display: none; }
      @media print {
        .report__top .black {
          display: block; } }
    .report__top .color {
      display: block; }
      @media print {
        .report__top .color {
          display: none; } }
  .report__brand {
    display: block; }
    .report__brand img {
      width: 400px;
      height: auto; }
      @media print {
        .report__brand img {
          width: 280px; } }
  .report__title h1 {
    font-size: 2rem;
    text-transform: uppercase; }
    @media print {
      .report__title h1 {
        font-size: 1.25rem; } }
  .report__title h3 {
    font-size: 1.8125rem;
    color: #707070;
    font-weight: 300; }
    @media print {
      .report__title h3 {
        font-size: 1rem; } }
  .report__header {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-between; }
  .report__result {
    display: flex;
    gap: 1.5rem; }
  .report__state {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: .675rem;
    flex-basis: 20%;
    max-width: 12.5rem; }
    .report__state .bi {
      font-size: 2.5rem;
      align-self: flex-start; }
    .report__state--success .bi {
      color: #1DB117; }
    .report__state--warning .bi {
      color: #FCC230; }
    .report__state--danger .bi {
      color: #FF7E83; }
    @media print {
      .report__state {
        color: #000 !important; } }
  .report__evaluation {
    flex-grow: 999;
    flex-basis: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem; }
    @media print {
      .report__evaluation {
        flex-direction: column; } }
  .report__history strong {
    margin-bottom: .5rem;
    letter-spacing: 1.1px;
    font-size: .6875rem;
    text-transform: uppercase;
    display: block; }
  .report__detail {
    display: flex;
    gap: 1.5rem; }
  .report__detail {
    flex-basis: 80%; }
  .report__visual {
    flex-basis: 20%; }
  .report__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem; }
  .report__navigation {
    display: flex;
    align-items: center; }

.firm {
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-size: 0.8125rem;
  color: #707070; }
  .firm span, .firm b {
    white-space: nowrap; }
  .firm b {
    color: #000; }
  .firm img {
    width: 100px;
    height: auto; }

/* Utilities */
@font-face {
  font-family: "project-icons";
  src: url("assets/fonts/project-icons.eot?426fb615c7dc515f792db1dd6c4eeb4f?#iefix") format("embedded-opentype"), url("assets/fonts/project-icons.woff2?426fb615c7dc515f792db1dd6c4eeb4f") format("woff2"), url("assets/fonts/project-icons.woff?426fb615c7dc515f792db1dd6c4eeb4f") format("woff"), url("assets/fonts/project-icons.ttf?426fb615c7dc515f792db1dd6c4eeb4f") format("truetype"), url("assets/fonts/project-icons.svg?426fb615c7dc515f792db1dd6c4eeb4f#project-icons") format("svg"); }

.ico {
  line-height: 1; }

.ico:before {
  font-family: project-icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top; }

.ico-building:before {
  content: "\f101"; }

.ico-burger:before {
  content: "\f102"; }

.ico-close:before {
  content: "\f103"; }

.ico-sample-2:before {
  content: "\f104"; }

.ico-sample:before {
  content: "\f105"; }

.ico-search:before {
  content: "\f106"; }

.ico-square-dash:before {
  content: "\f107"; }

.ico-square-plus:before {
  content: "\f108"; }

.ico-vial:before {
  content: "\f109"; }

.ico {
  display: flex; }
  .ico--big {
    font-size: 2.5rem; }
  .ico--medium {
    font-size: 1.75rem; }
  .ico--small {
    font-size: 1rem !important; }

.wrapper {
  display: flex;
  width: 100%;
  height: 100vh; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.thumb {
  max-width: 10rem; }
  .thumb img {
    width: 100%;
    height: auto; }

.c-gray {
  color: #8B95A2; }

.c-success {
  color: #1DB117; }

.c-warning {
  color: #FCC230; }

.c-danger,
.c-error {
  color: #D9232A; }

.fig {
  position: relative;
  font-size: 1.25rem;
  display: inline-block;
  color: #2D3D53; }
  .fig > em {
    z-index: 0; }
  .fig__badge {
    position: absolute;
    font-size: .75rem;
    z-index: 1;
    bottom: -.25rem;
    right: -.5rem; }
    .fig__badge--warning {
      color: #FCC230; }

.text-md {
  font-size: 1.25rem; }

.text--success {
  color: #1DB117; }

.text--warning {
  color: #FCC230; }

.text--error {
  color: #D9232A; }

.ico-size-md {
  font-size: 1.5rem; }

.no-border {
  border: none !important; }

.text-danger {
  color: #D9232A !important;
  font-size: .75rem; }

.text-underline {
  text-decoration: underline; }

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

.analysis {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .analysis__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 6rem;
    height: 6rem;
    border-radius: 3.5rem;
    margin-bottom: 1rem; }
    .analysis__circle::before {
      content: '';
      width: 6rem;
      height: 6rem;
      border: solid 4px #8B95A2;
      border-radius: 3.5rem;
      border-top-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      animation: rotation 2s infinite linear; }
  .analysis .bi {
    font-size: 3rem;
    color: #8B95A2; }

.batch__status {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .batch__status .bi, .batch__status .ico {
    font-size: 1.75rem; }
  .batch__status span {
    font-size: 1rem;
    font-weight: 500;
    margin-right: .5rem; }
  .batch__status.c-danger {
    color: #FF7E83; }

.border-bottom {
  border-bottom: solid 1px #f5f5f5; }

.subtitle {
  font-size: 1rem !important; }

.plan {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 1.5rem; }
  .plan__header {
    display: flex;
    flex-direction: column; }

.bg-gray {
  background-color: #E7EAF3; }

.bg-gray-light {
  background-color: #F8F9FB; }

.line {
  border-color: #FAFAFA;
  opacity: .25; }

.photo-dots {
  display: flex;
  align-items: center;
  gap: .25rem; }
  .photo-dots li {
    width: .5rem;
    height: .5rem;
    border-radius: .5rem;
    background-color: #377DFF; }
    .photo-dots li.clear {
      background-color: #ECF2FF !important; }

.card__title {
  text-align: left;
  font: normal normal 300 21px/28px Roboto;
  letter-spacing: 0px;
  color: #8B95A2;
  opacity: 1; }

.photo {
  display: flex;
  align-items: center;
  gap: .5rem; }

.photo .bi {
  font-size: 1.5rem; }

.photo span {
  color: #7897AB;
  font-size: .75rem; }

.card__status {
  display: flex;
  gap: 1rem;
  padding: 1rem; }

.flex-fill-50 {
  flex-basis: 50%; }

.repeat {
  background-color: #D9232A;
  color: #FFFFFF;
  width: .75rem;
  height: .75rem;
  line-height: .75rem;
  border-radius: .75rem;
  display: inline-block;
  text-align: center; }
