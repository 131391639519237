.link{
	color: $color-action;
	font-size: .875rem;
	background-color: transparent;
	border: none;
	i,em{
		font-size: 1.5rem;
	}
	&--icon{
		display: flex;
		align-items: center;
	}
	&:hover{
		cursor: pointer;
		color: $color-first;
	}
	.modal__foot &{
		text-transform: uppercase;
	}
}
