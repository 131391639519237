.mat-chip.ds-chip,
.ds-chip{
	font-size: 0.75rem;
	line-height: 100%;
	padding: .125em .675em;
	border: solid 2px #1E2F46;
	color: #1E2F46;
	border-radius: .5rem;
	font-weight: 600;
	&--danger{
		color: #D9232A;
		border-color: #D9232A;
	}
	&--fill{
		border-radius: 1rem;
		border-color:transparent;
		font-weight: 400;
		background-color: #E7EAF3;
		color: #8B95A2;
		&.ds-chip--danger{
			color: #D9232A;
			background-color: #FFE8E9;
		}
		&.ds-chip--success{
			color: #1DB117;
			background-color: #E1FCE0;
		}
		&.ds-chip--warning{
			color: #E38900;
			background-color: #FFF5E6;
		}
		&.ds-chip--gray{
			color: #FFFFFF;
			background-color: #8B95A2;
		}
	}
	&--icon{
		display: flex;
		gap: .5rem;
		align-items: center;
		.bi{
			font-size: 1.5em;
			line-height: 100%;
		}
		td & {
			display: inline-flex;
		}
	}
	&--action{
		color: #FFFFFF;
		background-color: #377DFF;
	}

}
