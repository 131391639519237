.content{
	padding: 1.5625rem;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	&__head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 2rem;
	}
	&__letterhead{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		&>*{
			line-height: 110%;
		}
	}
	&__title{
		flex-grow: 1;
		h2{
			font-size: 1.3125rem;
			font-weight: 400;
		}
		@media screen and (max-width:$lg) {
			margin-bottom: 1rem;
		}
	}
	&__tracking{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	&__actions{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}
