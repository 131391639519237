.modal{
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	&__contanier{
		background-color: $white;
		// max-width: 38em;
	}
	&__header{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		&.is-sticky{
			padding: 1.357rem 1.357rem 0;
			@media screen and (max-width:$sm) {
				position: absolute;
				border-radius: 1.5rem 1.5rem 0 0;
				top: 0;
				left: 0;
				width: 100%;
				background-color: $white;
			}
		}
		.mat-typography,
		h2{
			font-size: 1.25rem!important;
			font-weight: 300!important;
			margin: 0!important;
			max-width: 80%;
			&.modal__title{
				font-weight: 500!important;
				font-size: 1.5rem!important;
			}
		}
	}
	&__foot{
		display: flex;
		padding-top: 1.5rem;
	}
}
