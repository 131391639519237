.input {
	&--search {
		background-color: $white;
		border: 1px solid #ced4da;
		font-size: .875rem;
		padding: .75em .75em .75em 3em;
		border-radius: 2rem;
		background-image: url('./assets/images/ico-search.svg');
		background-position: left 1rem center;
		background-repeat: no-repeat;
		background-size: 1rem;
		width: 50%;
		max-width: 16rem;
		@media screen and (max-width:$md) {
			width: 100%;
		}
		&:focus{
			outline: none;
			border-color: $color-first;
		}
		&.input--align_end{
			padding: .75em 3em .75em .75rem;
			background-position: right 0 center;
			text-align: right;
			transition: all .3s ease-in-out;
			&:focus{
				background-position: right 1rem center;
				padding: .75em 3em .75em .75rem;
				text-align: left;
			}
		}
	}
	&--clear{
		border: none!important;
		background-color: transparent;
		&:focus{
			background-color: $white;
		}
	}
	&--text{
		padding: .5rem .5rem;
		border: solid 1px #CFD4D9;
		min-height: 36px;
		line-height: 100%;
	}
	&--large{
		text-align: center;
		letter-spacing: .5rem;
		font-size: 2.5rem;
		font-weight: 800;
		padding: .25rem;
		line-height: 100%;
	}
}

// input loader
.uploader-wrapper{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: dashed 1px #97989d;
	border-radius: .25rem;
	padding: 2rem;
	position: relative;
	width: 100%;
	@media screen and (min-width:$md) {
		width: 30rem;
	}
	.bi{
		color: #E7EAF3;
		font-size: 3rem;
		margin-bottom: 1rem;
	}
  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

  }
	h3{
		font-size: 1rem!important;
		margin: 0!important;
		line-height: 100%!important;

	}
}
.files-list {
  margin-top: 1.5rem;
  .single-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    display: flex;
    flex-grow: 1;
    .delete {
      display: flex;
      margin-left: 1rem;
      cursor: pointer;
			align-self: flex-end;
			.bi{
				color: $color-error;
				font-size: 1.5rem;
			}
    }
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0;
    }
    .size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
    }

		.percents{
			font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
			position: absolute;
			right: 0;
			top: -1.25rem;
		}
    .file-info {
      flex: 1;
    }
		.file-icon{
			margin-right: 1rem;
			.bi{
				font-size: 2.5rem;
				color: #007c02;
			}
		}
  }
}
.progress-cont {
  height: 7px;
  width: 100%;
  border-radius: 4px;
  background-color: #d0d0d0;
  position: relative;

  .progress {
    width: 0;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 4px;
    background-color:$color-first;
    transition: 0.5s all;
  }
}

textarea{
	padding: .5rem .5rem;
	border: solid 1px #CFD4D9;
	min-height: 4.5rem;
	line-height: 100%;
	display: block;
	resize: none;
	width: 100%;
	border-radius: .2rem;
}
