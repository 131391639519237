.txt{
	&--lg{
		font-size: 2rem!important;
	}
	&--md{
		font-size: 1.5625rem!important;
	}
	&--sm{
		font-size: 1rem!important;
	}
	&--xs{
		font-size: .75rem!important;
	}
	&--light{
		font-weight:300!important;
	}
	&--normal{
		font-weight:400!important;
	}
	&--black{
		font-weight:900!important;
	}
	&--third{
		color: $color-third!important;
	}
	&--action{
		color:$color-action!important;
	}
	&--success{
		color:$color-success!important;
	}
	&--white{
		color:$white!important;
	}
	&--dark{
		color:#000!important;
	}
	&--vertical{
		writing-mode: tb-rl
	}
	&--warning{
		color:$warning!important;
	}
	&--danger{
		color:$danger!important;
	}
	&--gray{
		color:#8B95A2!important;
	}
	&--uppercase{
		text-transform: uppercase;
	}
}
