.suggestion{
	background-color: #377DFF;
	color: $white;
	padding: .5rem;
	align-items: center;
	display: flex;
	gap: 1rem;
	border-radius: .5rem;
	font-size: .875rem;
	span{
		line-height: 120%;
	}
	.bi{
		font-size: 1.5rem;
	}
}
