// Colors
$white:#FFF;
$black:#000;
$color-first:#0045A2;
$color-second:#D9232A;
$color-third:#1E2F46;
$color-action:#377DFF;
$color-warning:#FCC230;
$color-success:#1DB117;
$color-danger:#FF7E83;
$color-error:$color-second;
$color-text:$black;
$color-bg:#FAFAFA;

// fonts
$base_size:16px;
$font_base:'Roboto', arial, sans-serif;

// Common
$transition-duration: .3s !default;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

:root{
	--ds-color-first:  #{$color-first};
	--ds-color-second: #{$color-second};
	--ds-color-third:  #{$color-third};
	--ds-color-four:  #{$color-action};
	--ds-font-family:  #{$font_base};
}

